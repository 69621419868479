<template>
  <common-trs-card>
    <v-col v-if="loading">
      <v-progress-circular
        class="ml-2"
        color="primary"
        indeterminate
      />
    </v-col>
    <v-card-title class="mx-4 align-baseline flex-column">
      <h3 class="trsText--text text-body-1">
        Roles
      </h3>
      <h4 class="trsText--text text-body-2 mt-3">
        This user will be assigned the following roles across all clients they manage
      </h4>
      <h5 class="error--text text-md-caption font-weight-medium mt-2">
        Note: Change in roles will be effective after logging in again.
      </h5>
    </v-card-title>

    <v-row
      v-for="(item, index) in allRoles"
      :key="index"
      class="mx-4"
    >
      <v-col cols="6">
        <v-checkbox
          v-model="selectedRoles"
          :value="item.role_name"
          :label="item.role_display_name"
          dense
          hide-details
          class="mt-0"
        />
      </v-col>
    </v-row>
    <v-card-actions class="mb-2 justify-end">
      <common-trs-btn
        type="secondary"
        class="mr-4"
        @click="goToAccess()"
      >
        Cancel
      </common-trs-btn>
      <common-trs-btn
        type="primary"
        :loading="loading"
        class=" white--text mr-4"
        :disabled="selectedRoles.length === 0"
        @click="saveRoles()"
      >
        Save Changes
      </common-trs-btn>
    </v-card-actions>
  </common-trs-card>
</template>

<script>
  import * as captableAdminService from '@/services/captable-admin-service'

  export default {
    name: 'UserAccess',

    data: () => ({
      loading: false,
      allRoles: [],
      selectedRoles: [],
    }),

    computed: {
      captableId () {
        return this.$store.getters['auth/capTableId']
      },
      selectedUserProfile () {
        return this.$store.getters['access/selectedUser']
      },
    },

    mounted () {
      this.getCaptableRoles()
    },

    methods: {
      async getCaptableRoles () {
        try {
          this.loading = true
          const resp = await captableAdminService.getCaptableRoleList()
          this.allRoles = resp.results
          this.selectedRoles = this.selectedUserProfile?.roles?.map(r => r.role_name)
        } catch (err) {
          this.$store.dispatch('app/handleError', {
            err,
            message: 'Failed to load roles list',
          })
        }
        this.loading = false
      },
      async saveRoles () {
        if (this.selectedUserProfile?.id) {
          try {
            this.loading = true
            const data = { roles: this.selectedRoles }
            await captableAdminService.updateCaptableUserRoles(this.captableId, this.selectedUserProfile.id, data)
            this.$store.commit('app/showMessage', { text: 'User role updated successfully!' })
          } catch (err) {
            this.$store.dispatch('app/handleError', {
              err,
              message: 'Sorry! Failed to update roles. Please try again later.',
            })
          }
          this.loading = false
        } else {
          this.$store.dispatch('app/handleError', {
            message: 'Sorry! Unable to update roles. Please try again later.',
          })
        }
      },
      goToAccess () {
        this.$router.push({ name: 'SettingsAccess' })
      },
    },
  }
</script>
